
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "VerifyEmail",
  components: {},
  setup() {
    // const router = useRouter();
    // const route = useRoute();

    // const verifyURL = ref("");


    return {};
  },
  methods: {
     submit() {
//alert("asd");
 const router = useRouter();
 router.push({
                name: "Plans",
             

              });
     }
  },
});
